function teaserslider() {
  $(".teaserslider").owlCarousel({
    loop: false,
    margin: 30,
    dots: false,
    nav: false,
    navText: [
      '<i class="flaticon-right-arrows"></i>',
      '<i class="flaticon-right-arrows"></i>',
    ],
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 4,
      },
    },
  });
}

export { teaserslider };

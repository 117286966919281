/* General + Third-Party */
import jQuery from "jquery";
import { lazyload } from "./globals/lazyload";
import owlCarousel from "owl.carousel";

/* Layout-Parts */
// import { menu } from './layout/menu';
import { toggleNavigation } from "./layout/navigation";
import { fixBootstrapToggle } from "./layout/navigation";
import { headlineHeight } from "./layout/headlineHeight";

/* Blocks */
import { teaserslider } from "../../blocks/teaserslider/script";
import { accStateChange } from "../../blocks/accordion/script";
import { accViewPort } from "../../blocks/accordion/script";
import { accURLOpener } from "../../blocks/accordion/script";
import { galleryIsotopeSorter } from "../../blocks/gallery-sort/script";
import { galleryCategoryToggle } from "../../blocks/gallery-sort/script";
import { galleryFancyBoxCaption } from "../../blocks/gallery-sort/script";

jQuery(document).ready((_) => {
  lazyload();
  toggleNavigation();
  fixBootstrapToggle();
  accStateChange();
  // accViewPort();
  accURLOpener();
  teaserslider();
  headlineHeight();
  galleryIsotopeSorter();
  galleryCategoryToggle();
  galleryFancyBoxCaption();
});

function headlineHeight() {
  if ($(window).width() > 768) {
    $(".block-contact-form").each(function () {
      var highestBox = 0;
      $(".form-headline", this).each(function () {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }
      });

      $(".form-headline", this).height(highestBox);
    });
  }

  if ($(window).width() > 768) {
    $(".employee-row").each(function () {
      var highestBox = 0;
      $(".employee-info-container", this).each(function () {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }
      });

      $(".employee-info-container", this).height(highestBox);
    });
  }

  if ($(window).width() > 768) {
    $(".employee-row").each(function () {
      var highestBox = 0;
      $(".employee-picture img", this).each(function () {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }
      });

      $(".employee-picture img", this).height(highestBox);
    });
  }
}

export { headlineHeight };

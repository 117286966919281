function toggleNavigation() {
  jQuery("#main-menu-id li.children button").click(function (event) {
    event.preventDefault();
    jQuery(this).toggleClass("open");
    jQuery(this).next().toggleClass("open");
  });

  jQuery('#main-menu-id li.children a[href="#"]').click(function (event) {
    event.preventDefault();
    jQuery(this).next().toggleClass("open");
    jQuery(this).next().next().toggleClass("open");
  });
}
export { toggleNavigation };

function fixBootstrapToggle() {
  // -- reset navbar-toggle
  var $toggle = $(".site-header .navbar-toggler"),
    $navbar = $(".site-header .navbar-collapse"),
    $backdrop = $(".navbar-backdrop");

  $toggle.on("click", function () {
    $(this).toggleClass("collapsed");
    $navbar.toggleClass("show");
    $backdrop.toggleClass("show");
    $("body").toggleClass("open-nav");
    $("html").toggleClass("disable-scroll");
    return false;
  });

  // -- reset navbar by click anywhere

  $("body").click(function (event) {
    var $target = $(event.target);

    if ($(".site-header .navbar-collapse.show").length > 0) {
      if (
        $target.parents(".navbar-collapse").length === 0 &&
        $target.parents(".navbar-toggler").length === 0
      ) {
        $(".navbar-backdrop").removeClass("show");
        $(".site-header .navbar-collapse").removeClass("show");
        $(".site-header .navbar-toggler").removeClass("collapsed");
        $("body").removeClass("open-nav");
        $("html").removeClass("disable-scroll");
      }
    }
  });

  if (screen.width > 992) {
    $(window).on("scroll", function (event) {
      var $target = $(event.target);

      if ($(".site-header .dropdown.show").length > 0) {
        if ($target.parents(".dropdown").length === 0) {
          $(".site-header .dropdown").removeClass("show");
        }
      }
    });
  }
}
export { fixBootstrapToggle };

import "@fancyapps/fancybox";
import jQueryBridget from "jquery-bridget";
import Isotope from "isotope-layout";

jQueryBridget("isotope", Isotope, $);

function galleryIsotopeSorter() {
  let $grid = $(".gallery-sort-images-row").isotope({});

  $(".gallery-sort-images-buttons-row").on(
    "click",
    ".gallery-sort-filter-button",
    function () {
      let filterValue = $(this).attr("data-filter");

      if (filterValue == "*") {
        $grid.isotope({
          filter: filterValue,
        });
      }
      if (filterValue != "*") {
        $grid.isotope({
          filter: "." + filterValue,
        });
      }
    }
  );
}

export { galleryIsotopeSorter };

function galleryCategoryToggle() {
  $(".gallery-sort-filter-button").click(function () {
    $(".gallery-sort-filter-button-active").removeClass("gallery-sort-filter-button-active");
    $(this).toggleClass("gallery-sort-filter-button-active");
  });
}

export { galleryCategoryToggle };



function galleryFancyBoxCaption() {
  $( '.gallery-sort-fancybox' ).fancybox({
    selector : '.gallery-sort-picture:visible > a',
    infobar : true,
    caption : function( instance, item ) {
      var caption = $(this).data('caption') || '';
      return (caption)
    }
  });



}

export { galleryFancyBoxCaption };
